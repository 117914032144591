(function ($) {
  jQuery(document).bind(
    "gform_post_render",
    function (event, form_id, current_page) {
      if (form_id === 2) {
        $(".group-me").wrapAll('<div class="groupped" />');
        $(".groupped").wrap('<div class="groupped-form" />');
        $(".site-footer .gform_footer.top_label").appendTo(".groupped-form");
      }
    }
  );
})(jQuery);
