(function ($) {
  function toggleMobileSubmenu() {
    $(this).toggleClass("expanded");
    var currSubMenu = $(this).find(".sub-menu");
    var currSubMenuHeight = currSubMenu.outerHeight();
    currSubMenu.css(
      "margin-top",
      currSubMenu.hasClass("hidden") ? 20 : -1 * currSubMenuHeight
    );
    // setTimeout(function () {
    currSubMenu.toggleClass("hidden");
    // }, 200);
  }

  function initialMobileSubmenuHide() {
    var allSubMenu = $(".mobile-menu .sub-menu");
    allSubMenu.each(function () {
      var currSubMenu = $(this);
      var currSubMenuHeight = currSubMenu.outerHeight();
      currSubMenu.css(
        "margin-top",
        currSubMenu.hasClass("hidden") ? 20 : -1 * currSubMenuHeight
      );
      currSubMenu.addClass("hidden");
    });
  }

  $(document).ready(function () {
    // $(".nav-container-items-item.pull-down").wrapAll(
    //   '<div class="bottom-container col-12" />'
    // );
    // $(".nav-container-items-item:not(.pull-down)").wrapAll(
    //   '<div class="top-container col-12" />'
    // );

    let menuFirstRun = true;

    $(".mobile-menu-close").click(function () {
      $("body").toggleClass("menu-open");
      $(".mobile-menu").toggleClass("show-menu");

      setTimeout(function () {
        $(".mobile-menu").toggleClass("d-none");
      }, 350);
    });

    $(".menu-toggle").click(function () {
      $("body").toggleClass("menu-open");
      $(".mobile-menu").toggleClass("d-none");

      setTimeout(function () {
        $(".mobile-menu").toggleClass("show-menu");
      }, 50);
      if (menuFirstRun) {
        initialMobileSubmenuHide();
        menuFirstRun = false;
      }
    });

    $(".mobile-menu li.menu-item-has-children").click(toggleMobileSubmenu);
  });
})(jQuery);
