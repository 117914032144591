/*-------------------------------------------------------*\
    Exposes get/set methods to read/write cookies

    See .get and .set functions for more documentation
\*-------------------------------------------------------*/

ThemeJS.Cookies = (function () {
  function set_cookie(cname, cvalue, exdays) {
    if (!cvalue || $.isEmptyObject(cvalue) || !cvalue.length) {
      cvalue = "";
      exdays = 0;
    }
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
  }

  function set_the_cookie(cname, cvalue, exdays) {
    if (exdays) {
      var date = new Date();
      date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = cname + "=" + cvalue + expires + ";path=/";
  }

  function get_cookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return JSON.parse(c.substring(name.length, c.length));
      }
    }
    return "";
  }

  /*-------------------------------------------------------*\
        Read cookies by name


        Params:
            cookie_names:
                String/Array( String[] )

        Return:
            If cookie_names = String:
                value of cookie
            If cookie_names = Array
                Object of signatue
                {
                    cookie_name : cookie_value
                    ...
                }

    \*-------------------------------------------------------*/
  function get(cookie_names) {
    if (typeof cookie_names == "string") {
      return get_cookie(cookie_names);
    }
    var cookies = {};
    $.each(cookie_names, function (index, cookie_name) {
      cookies[cookie_name] = get_cookie(cookie_name);
    });
    return cookies;
  }

  /*-------------------------------------------------------*\
        Set cookies

        Params:
            cookies:
                Object of signature
                {
                    cookie_name : cookie_value
                    ...
                }

    \*-------------------------------------------------------*/
  function set(cookies) {
    $.each(cookies, function (cookie_name, cookie_data) {
      if (cookie_data) {
        if (typeof cookie_data != "object" || $.isArray(cookie_data)) {
          cookie_data = { value: cookie_data };
        }
        if (!cookie_data.hasOwnProperty("expires")) {
          cookie_data.expires = 1;
        }
      } else {
        cookie_data = { value: "", expires: 0 };
      }
      set_cookie(cookie_name, cookie_data.value, cookie_data.expires);
    });
  }

  return {
    setSingle: set_the_cookie,
    get: get,
    set: set,
  };
})();
