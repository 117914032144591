(function ($) {
  $(document).ready(function () {
    if ($(".card-container").length) {
      $(".action-cards .card-container").matchHeight();
      $(window).resize(function () {
        $(".action-cards .card-container").matchHeight();
      });
    }
  });
})(jQuery);
